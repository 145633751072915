define("ember-quickstart/components/freestyle-source", ["exports", "ember-freestyle/components/freestyle-source"], function (_exports, _freestyleSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _freestyleSource.default;
    }
  });
});