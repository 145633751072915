define("ember-quickstart/controllers/freestyle", ["exports", "ember-freestyle/controllers/freestyle"], function (_exports, _freestyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _freestyle.default.extend({
    emberFreestyle: inject.service()
  });

  _exports.default = _default;
});