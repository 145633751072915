define("ember-quickstart/helpers/root-url", ["exports", "ember-router-helpers/helpers/root-url"], function (_exports, _rootUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rootUrl.default;
    }
  });
  Object.defineProperty(_exports, "rootUrl", {
    enumerable: true,
    get: function get() {
      return _rootUrl.rootUrl;
    }
  });
});