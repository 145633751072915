define("ember-quickstart/templates/admin/targets-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lINKhnvr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"style\"],[12],[2,\" iframe {border:none; outline:none; height: calc(100vh - 40px);} \"],[13],[2,\"\\n\"],[10,\"iframe\"],[14,1,\"bibiFrame\"],[15,\"src\",[31,[[30,[36,0],[\"/2021/target.php?location_id=\",[32,0,[\"internationalization\",\"location\",\"id\"]]],null]]]],[14,\"width\",\"100%\"],[14,\"height\",\"100vh\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/admin/targets-v2.hbs"
    }
  });

  _exports.default = _default;
});