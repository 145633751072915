define("ember-quickstart/components/admin/question-content-lien", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table w-100">
    <tbody>
      <tr>
        <td>
          <div class="form-group">
           <label for="lien">
             Link:
            </label>
            <Input
              @type="text"
              @class="form-control"
              @name="lien"
              @value={{@model.lien}}
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  */
  {"id":"K7KeJlYq","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"table\"],[14,0,\"table w-100\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n         \"],[10,\"label\"],[14,\"for\",\"lien\"],[12],[2,\"\\n           Link:\\n          \"],[13],[2,\"\\n          \"],[8,\"input\",[],[[\"@type\",\"@class\",\"@name\",\"@value\"],[\"text\",\"form-control\",\"lien\",[32,1,[\"lien\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"ember-quickstart/components/admin/question-content-lien.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});