define("ember-quickstart/components/freestyle-showdown-content", ["exports", "ember-freestyle/components/freestyle-showdown-content"], function (_exports, _freestyleShowdownContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _freestyleShowdownContent.default;
    }
  });
});