define("ember-quickstart/templates/admin/guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aHnVJgdg",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12 mb-2\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-10\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"\\n          Guide\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-2 text-right\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12 mb-3\"],[12],[2,\"\\n    \"],[8,\"admin/breadcrumb\",[],[[\"@entries\"],[[30,[36,1],[[30,[36,0],null,[[\"name\",\"route\"],[\"Guide\",\"admin.guide\"]]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12 mb-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-6\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-6 text-right\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12 white-bg pt-3 pb-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"guide\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"text\",[32,0,[\"link\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12 text-center\"],[12],[2,\"\\n        \"],[10,\"hr\"],[12],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"submit\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0],\"updateGuide\"],null]],null],[12],[2,\"\\n          Edit\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"array\",\"if\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/admin/guide.hbs"
    }
  });

  _exports.default = _default;
});