define("ember-quickstart/helpers/froala-html", ["exports", "ember-froala-editor/helpers/froala-html"], function (_exports, _froalaHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _froalaHtml.default;
    }
  });
  Object.defineProperty(_exports, "froalaHtml", {
    enumerable: true,
    get: function get() {
      return _froalaHtml.froalaHtml;
    }
  });
});