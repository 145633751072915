define("ember-quickstart/templates/app/register/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LvECMXK+",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[\"app.register\"],null]],null],[12],[2,\"\\n  back\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"transition-to\",\"on\"]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/app/register/avatar.hbs"
    }
  });

  _exports.default = _default;
});