define("ember-quickstart/components/freestyle-variant-list", ["exports", "ember-freestyle/components/freestyle-variant-list"], function (_exports, _freestyleVariantList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _freestyleVariantList.default;
    }
  });
});