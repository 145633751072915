define("ember-quickstart/templates/admin/lstream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mpURwggm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-12 mb-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-10\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"\\n        Live Stream\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-2 text-right\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-12 mb-3\"],[12],[2,\"\\n  \"],[8,\"admin/breadcrumb\",[],[[\"@entries\"],[[30,[36,1],[[30,[36,0],null,[[\"name\",\"route\"],[\"LiveStream\",\"admin.lstream\"]]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-12 mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-6\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-6 text-right\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"array\"]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/admin/lstream.hbs"
    }
  });

  _exports.default = _default;
});