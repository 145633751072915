define("ember-quickstart/components/admin/breadcrumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="breadcrumb">
    {{#each @entries as |entry|}}
      <li class="breadcrumb-item">
        {{#if entry.model}}
          <LinkTo @route={{entry.route}} @model={{entry.model}}>
            {{entry.name}}
          </LinkTo>
        {{else}}
          <LinkTo @route={{entry.route}}>
            {{entry.name}}
          </LinkTo>
        {{/if}}
      </li>
    {{/each}}
  </ul>
  */
  {"id":"71qzqKDv","block":"{\"symbols\":[\"entry\",\"@entries\"],\"statements\":[[10,\"ul\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"model\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[32,1,[\"route\"]],[32,1,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"link-to\",[],[[\"@route\"],[[32,1,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}","meta":{"moduleName":"ember-quickstart/components/admin/breadcrumb.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});