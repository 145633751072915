define("ember-quickstart/templates/admin/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "daTyf3Ax",
    "block": "{\"symbols\":[],\"statements\":[[10,\"iframe\"],[14,\"width\",\"100%\"],[14,5,\"border: none; height: 92vh;\"],[15,\"src\",[31,[\"https://weber-digital-training.com/2021/analytics.php?location_id=\",[32,0,[\"internationalization\",\"location\",\"id\"]]]]],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/admin/analytics.hbs"
    }
  });

  _exports.default = _default;
});