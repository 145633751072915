define("ember-quickstart/components/admin/question-content-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table w-100">
    <tbody>
     <tr>
     <td>
       <div class="form-group">
         <label for="linkname">
           {{t @model.labelLinkname.keyword}}:
         </label>
         <Input
           @type="text"
           @class="form-control"
           @name="linkname"
           @value={{@model.linkname}}
         />
       </div>
     </td>
   </tr>
      <tr>
        <td>
          <div class="form-group">
            <label for="link">
              Link Name
            </label>
            <Input
              @type="text"
              @class="form-control"
              @name="link"
              @value={{@model.link}}
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  */
  {"id":"vLhOOmpn","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"table\"],[14,0,\"table w-100\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n   \"],[10,\"tr\"],[12],[2,\"\\n   \"],[10,\"td\"],[12],[2,\"\\n     \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n       \"],[10,\"label\"],[14,\"for\",\"linkname\"],[12],[2,\"\\n         \"],[1,[30,[36,0],[[32,1,[\"labelLinkname\",\"keyword\"]]],null]],[2,\":\\n       \"],[13],[2,\"\\n       \"],[8,\"input\",[],[[\"@type\",\"@class\",\"@name\",\"@value\"],[\"text\",\"form-control\",\"linkname\",[32,1,[\"linkname\"]]]],null],[2,\"\\n     \"],[13],[2,\"\\n   \"],[13],[2,\"\\n \"],[13],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,\"for\",\"link\"],[12],[2,\"\\n            Link Name\\n          \"],[13],[2,\"\\n          \"],[8,\"input\",[],[[\"@type\",\"@class\",\"@name\",\"@value\"],[\"text\",\"form-control\",\"link\",[32,1,[\"link\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}","meta":{"moduleName":"ember-quickstart/components/admin/question-content-link.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});