define("ember-quickstart/components/spinner-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container-fluid" id="nav_default">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  */
  {"id":"wt2tubTU","block":"{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-fluid\"],[14,1,\"nav_default\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"double-bounce1\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"double-bounce2\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"ember-quickstart/components/spinner-default.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});