define("ember-quickstart/templates/app/sav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "peiqul/u",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"col-12 offset-lg-1 col-lg-10 border-box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-12 bg-white\"],[14,1,\"screen_login\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row header-weber\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-12 no-padding\"],[12],[2,\"\\n          \"],[10,\"img\"],[14,\"src\",\"/assets/img/screen/screen_sav/header.png\"],[14,0,\"w-100\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"hw5 font6 screen_formation_header_title w-100 text-center\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"service-procedure\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row pt-5\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-12 col-lg-10 offset-lg-1\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"translation\",\"text\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"html-safe\"]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/app/sav.hbs"
    }
  });

  _exports.default = _default;
});