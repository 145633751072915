define("ember-quickstart/components/admin/question-content-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table w-100">
    <tbody>
      <tr>
        <td>
          <div class="form-group">
            <label for="youtube">
              {{t @model.labelYoutube.keyword}}:
            </label>
            <Input
              @type="text"
              @class="form-control"
              @name="youtube"
              @value={{@model.youtube}}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td class="w-100">
          <div class="form-group">
            <label for="text">
              {{t @model.labelTexte.keyword}}:
            </label>
            {{#if @model.mediatexte}}
              <FroalaEditor
                @content={{html-safe @model.mediatexte.target}}
                @update={{to-string (fn (mut @model.mediatexte.target))}}
              />
            {{/if}}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  */
  {"id":"qSTov967","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"table\"],[14,0,\"table w-100\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,\"for\",\"youtube\"],[12],[2,\"\\n            \"],[1,[30,[36,4],[[32,1,[\"labelYoutube\",\"keyword\"]]],null]],[2,\":\\n          \"],[13],[2,\"\\n          \"],[8,\"input\",[],[[\"@type\",\"@class\",\"@name\",\"@value\"],[\"text\",\"form-control\",\"youtube\",[32,1,[\"youtube\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[14,0,\"w-100\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,\"for\",\"text\"],[12],[2,\"\\n            \"],[1,[30,[36,4],[[32,1,[\"labelTexte\",\"keyword\"]]],null]],[2,\":\\n          \"],[13],[2,\"\\n\"],[6,[37,5],[[32,1,[\"mediatexte\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"froala-editor\",[],[[\"@content\",\"@update\"],[[30,[36,0],[[32,1,[\"mediatexte\",\"target\"]]],null],[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,1,[\"mediatexte\",\"target\"]]],null]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"mut\",\"fn\",\"to-string\",\"t\",\"if\"]}","meta":{"moduleName":"ember-quickstart/components/admin/question-content-youtube.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});