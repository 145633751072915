define("ember-quickstart/templates/admin/testiframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v7W5PRTu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"style\"],[12],[2,\"\\n  iframe {border:none; outline:none; height: calc(100vh - 40px);}\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"iframe\"],[14,1,\"bibiFrame\"],[14,\"src\",\"\"],[14,\"width\",\"100%\"],[14,\"height\",\"100vh\"],[12],[13],[2,\"\\n\\n\"],[10,\"script\"],[14,4,\"text/javascript\"],[12],[2,\"\\nvar article = document.getElementById('admin');\\nvar location_id = article.dataset.location// \\\"3\\\"\\n\\nvar iframe = document.getElementById('bibiFrame').src='http://weber-training-staging.littlelessconversation.net/2021/livestream.php?location_id='+location_id;\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/admin/testiframe.hbs"
    }
  });

  _exports.default = _default;
});