define("ember-quickstart/templates/app/training", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S5Qe5sYq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-12 offset-lg-1 col-lg-10 border-box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-12 bg-white\"],[14,1,\"screen_login\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row header-weber\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-12 no-padding\"],[12],[2,\"\\n          \"],[10,\"img\"],[14,\"src\",\"/assets/img/screen/screen_common/header_connection.png\"],[14,0,\"w-100\"],[12],[13],[2,\"\\n          \"],[11,\"div\"],[24,0,\"hw5 font6 screen_formation_header_title w-100 text-center\"],[4,[38,0],null,[[\"keyword\",\"absolute\"],[\"the-story\",true]]],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"the-story\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row pt-5\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-12 col-lg-10 offset-lg-1 text-center\"],[12],[2,\"\\n          \"],[3,\" autoplay webkit-playsinline playsinline \"],[2,\"\\n          \"],[10,\"video\"],[14,1,\"videoPlayer\"],[14,0,\"w-100 mb-3\"],[14,\"controls\",\"\"],[14,\"preload\",\"auto\"],[12],[2,\"\\n            \"],[10,\"source\"],[15,\"src\",[30,[36,2],[\"video.mp4\"],null]],[14,4,\"video/mp4\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[11,\"div\"],[24,1,\"screen_story_bt_skip\"],[24,0,\"w-100 mb-5 font1\"],[24,5,\"cursor:pointer;\"],[4,[38,4],[\"click\",[30,[36,3],[\"app.register\"],null]],null],[4,[38,0],null,[[\"keyword\"],[\"skip-video\"]]],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"skip-video\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"jumptool-content\",\"t\",\"bucket-asset\",\"transition-to\",\"on\"]}",
    "meta": {
      "moduleName": "ember-quickstart/templates/app/training.hbs"
    }
  });

  _exports.default = _default;
});